import { CopyOutlined } from "@ant-design/icons";
import { isEmpty } from "@antv/util";
import { message, Spin } from "antd";
import copy from "copy-to-clipboard";
import { cloneDeep, map } from "lodash";
import forEach from "lodash.foreach";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { comingSoonImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getVideos from "../../../newApi/videos/getVideos";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import ScrollLoadWrapper from "../../general/components/ScrollLoadWrapper ";
import VideosDetailModal from "../component/VideosDetailModal";

const PAGESIZE = 10;
// markup
const VideosListingPage = (props) => {
  // const location = useLocation();
  const { t, languages } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [languageType, setLanguageType] = useState("all");
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [viewVideo, setViewVideo] = useState(false);

  useEffect(() => {
    getData((page - 1) * PAGESIZE);
  }, [page]);

  // useEffect(() => {
  //   getData((1 - 1) * PAGESIZE);
  // }, [languageType]);

  function getData(skip) {
    setLoading(true);
    // console.log("getData");
    let filteredGroup = {};
    // if (!isNaN(parseInt(languageType))) {
    //   filteredGroup.languageType = parseInt(languageType);
    // }
    filteredGroup.languageType = languages === "en" ? 0 : 1;

    getVideos(PAGESIZE, skip, {
      ...filteredGroup,
      sort: {
        sequence: 1,
      },
    })
      .then((res) => {
        let data = get(res, "data");
        if (!isEmpty(data)) {
          let temp_data = cloneDeep(dataSource);
          forEach(data, (item, index) => {
            temp_data.push(item);
          });
          setDataSource(temp_data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // const newsTypes = [
  //   {
  //     value: "all",
  //     text: t("allLanguage", sourceKey.newAndVideo),
  //   },
  //   {
  //     value: "0",
  //     text: t("english", sourceKey.newAndVideo),
  //   },
  //   {
  //     value: "1",
  //     text: t("chinese", sourceKey.newAndVideo),
  //   },
  // ];
  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {/* 视频  */}
                  {t("video", sourceKey.newAndVideo)}
                </div>
              </React.Fragment>
            }
          >
            {/* <div className="grid grid-cols-6 gap-2">
              {map(newsTypes, (item, index) => {
                return (
                  <div
                    className={`col-span-2 p-4 rounded-2xl text-center cursor-pointer font-semibold m-2 text-base ${
                      languageType === item.value
                        ? "bg-main-color-gradient"
                        : "box-bg-color"
                    } `}
                    style={
                      {
                        // color: `${languageType === item.value ? "" : "grey"}`,
                      }
                    }
                    onClick={() => {
                      // setLanguageType(item.value);
                      if (languageType !== item.value) {
                        setDataSource([]);
                        let page_value = 1;
                        setPage(page_value);
                        setLanguageType(item.value);
                      }
                    }}
                  >
                    {get(item, "text")}
                  </div>
                );
              })}
            </div> */}

            <div className="mx-2 pb-4">
              <ScrollLoadWrapper
                style={{ height: "73vh" }}
                onScrolledBottom={() => {
                  let length = cloneDeep(page);
                  setPage(length + 1);
                }}
              >
                <div className="grid grid-cols-4 gap-2">
                  {isEmpty(dataSource) && loading === false && (
                    <div className="col-span-4 flex justify-center mt-4">
                      <img src={comingSoonImg} style={{ height: "70%" }} />
                    </div>
                  )}
                  {map(dataSource, (item, index) => {
                    let split_content;
                    if (!isEmpty(get(item, "content"))) {
                      split_content = get(item, "content").split("\n");
                    }
                    return (
                      <div
                        className="col-span-4 sm:col-span-2 theme-bg-color pb-8 mt-5 mr-2 "
                        style={{
                          borderTopLeftRadius: "90px",
                          borderTopRightRadius: "90px",
                          // borderBottomRightRadius: "90px",
                          // borderBottomLeftRadius: "90px",
                          //   width: "100%",
                        }}
                        key={index}
                      >
                        <div className="">
                          <img
                            src={get(item, "videoThumbnail")}
                            className="img-cover"
                            style={{
                              width: "100%",
                              borderTopLeftRadius: "50px",
                              borderTopRightRadius: "50px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedData(item);
                              setViewVideo(true);
                            }}
                          />
                        </div>
                        <div
                          className="mb-4 "
                          style={{
                            borderBottomRightRadius: "90px",
                            borderBottomLeftRadius: "90px",
                          }}
                        >
                          {/* <span>
                            {moment(get(item, "createdDate")).format(
                              "d MMMM YYYY"
                            )}
                          </span> */}
                          <div className="flex justify-between mx-6 mt-4">
                            <span className="font-bold text-lg">
                              {get(item, "title")}
                            </span>
                            {/* <span>
                              <ShareAltOutlined style={{ fontSize: 31 }} />
                            </span> */}
                            <span>
                              <CopyOutlined
                                onClick={() => {
                                  message.success(
                                    t("successCopy", sourceKey.newAndVideo)
                                  );
                                  copy(get(item, "videoLink"));
                                }}
                                style={{ fontSize: 22 }}
                              />
                            </span>
                          </div>
                          <div className="m-2 break-words">
                            {map(split_content, (content, index) => {
                              if (isEmpty(content)) {
                                return <br />;
                              } else {
                                return <div>{content}</div>;
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ScrollLoadWrapper>
            </div>
          </NavHeader>

          <VideosDetailModal
            data={selectedData}
            visible={viewVideo}
            onClose={() => {
              setSelectedData({});
              setViewVideo(false);
            }}
          />
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideosListingPage);
