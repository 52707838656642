import { apiUrl, _axios_base_get_list } from "..";
import { videosPrefix } from "./index";
// const PAGESIZE = 100;

export default function getVideos(limit, skip = 0, query = {}) {
  return _axios_base_get_list(
    `${apiUrl}/${videosPrefix}/getVideos`,
    limit,
    skip,
    query
  );
}
