import { Modal } from "antd";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";

// const PAGESIZE = 10;
// markup
const VideosDetailModal = (props) => {
  // const location = useLocation();
  const [visible, setVisible] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  useEffect(() => {
    setSelectedData(props.data);
  }, [props.data]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
    setSelectedData({});
  }

  const _renderContent = () => {
    return (
      <div className="flex justify-center">
        <div className="place-items-center">
          <MediaQuery maxWidth={670}>
            <ReactPlayer
              url={get(selectedData, "videoLink")}
              controls={true}
              width={320}
            />
          </MediaQuery>
          <MediaQuery minWidth={671}>
            <ReactPlayer url={get(selectedData, "videoLink")} controls={true} />
          </MediaQuery>
          <div className="bg-white p-4">
            <div className="font-bold pb-2">{get(selectedData, "title")}</div>
            <div>{get(selectedData, "content")}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Modal
        className="modal-body-background-transparent"
        centered
        maskClosable
        width="40%"
        mask
        footer={null}
        closable={true}
        visible={visible}
        onCancel={() => {
          close();
        }}
      >
        {_renderContent()}
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideosDetailModal);
